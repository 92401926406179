import React, { useEffect, useState } from "react";
import "./allcourses.css";
import AllCoursesBanner from "./AllCoursesBanner/AllCoursesBanner";
import Footer from "../../components/Footer/Footer";
import CryptoJS from "crypto-js";
import Skeleton from "react-loading-skeleton";
import { getCourses } from "./functions/getAll";
import { MdPlayLesson } from "react-icons/md";
import { useNavigate, useParams } from "react-router";
const AllCourses = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [originalCourses, setOriginalCourses] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const localData = localStorage.getItem("elmataryapp");
  const decryptedBytes = localData && CryptoJS.AES.decrypt(localData, "111");
  const userData =
    decryptedBytes && JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
const {id} = useParams()
  useEffect(() => {
    getCourses(userData, setPageLoading, setCourses, setOriginalCourses, id);
  }, []);
  console.log(courses);
  return (
    <>
      <div className="allcourses">
        {/* <AllCoursesBanner /> */}
        <div className="all_course-title">
          <h3
            style={{
              marginTop: "50px",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              fontSize: "32px",
              fontWeight: "bold",
            }}
          >
           Tap on the university below to see the courses available to it

          </h3>
          <p
            style={{
              textAlign: "center",
              maxWidth: "800px",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "30px",
              fontSize: "15px",
              color: "grey",
            }}
          >
           Each University has a special courses designed to its students , so we guaranteed to you a high level of education and experience.
          </p>
        </div>
        <h2 style={{textAlign:"center", fontSize:"30px", fontWeight:900}}>Courses</h2>

          <div className="courses_content py-2">
          {pageLoading ? (
            <div style={{ width: "100vw " }}>
              <Skeleton count={12} height={34} />
            </div>
          ) : courses && courses?.length > 0 ? (
            courses.map((item, index) => {
              return (
                <div
                  key={item.course_id}
                  className="course-card"
                  onClick={() => {
                    navigate(
                      item?.own
                        ? "/studentCourses"
                        : "/units?course_id=" + item?.course_id,
                      { state: { course: item } }
                    );
                  }}
                >
                  <div className="course-price">
                    {item?.course_price == 0 ? "Free" : item?.course_price}
                  </div>
                  <div class="main">
                    <img
                      class="tokenImage"
                      src={item?.course_photo_url}
                      alt=""
                    />
                    {/* <div className="course-title">
                      <h2></h2>
                    </div> */}

                    <div className="course-content-body">
                      <h4>{item?.course_name}</h4>
                      <p class="description">
                        {item?.course_content?.length > 50
                          ? item?.course_content?.substring(0, 50) + "..."
                          : item?.course_content}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="empty">
              <MdPlayLesson className="icon" />
              <h5>No Courses</h5>
            </div>
          )}
        </div>
        {/* <CoursesPagination /> */}
      </div>
      <Footer />
    </>
  );
};

export default AllCourses;
