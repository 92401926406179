import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormContainer,
  FormGroup,
  SubmitButton,
  BackButton,
} from "./LoginFormStyles";
import axios from "axios";
import { toast } from "react-toastify";

function ResetPassword() {
  const [resetData, setResetData] = useState({ email: "" });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResetData((prevState) => ({ ...prevState, [name]: value }));
  };

  const generateRandomCode = () => {
    const nums = "1234567890";
    let code = "";
    Array(6)
      .fill(0)
      .map((item, index) => {
        code += nums[Math.floor(Math.random() * 6)];
      });
    return code;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const code = generateRandomCode()
    axios
      .post(
        "https://medicotoon.com/medicotoon/backend/user/auth/send_code_to_mail.php",
        {
          email: resetData?.email,
          code: code,
        }
      )
      .then((res) => {
        if (res?.data?.status) {
          toast.success("Check You Email");
          navigate("/confirm-code/" + resetData?.email, {
            state: {
              code: code,
            },
          });
        } else {
          toast.error(res?.data?.message);
        }
      });
  };

  return (
    <FormContainer>
      <BackButton onClick={() => navigate(-1)}>&larr; Back</BackButton>
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <label htmlFor="resetEmail">Email:</label>
          <input
            type="email"
            id="resetEmail"
            name="email"
            value={resetData.email}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <SubmitButton type="submit">Request Reset</SubmitButton>
      </form>
    </FormContainer>
  );
}

export default ResetPassword;
