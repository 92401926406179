import axios from "axios";
import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { handleLogOut } from "../../App";
import { base_url } from "../../constants";
import TableofContent from "./components/TableofContent/TableofContent";
// import { Videos } from "./libs/Videos";
import ContentLoader from "react-content-loader";
import { useDispatch } from "react-redux";
import { showToogleTooltib } from "../../store/reducers/tooltibReducer";
import "./style.css";
const CourseContent = () => {
  const localData = localStorage.getItem("elmataryapp");
  const decryptedBytes = localData && CryptoJS.AES.decrypt(localData, "111");
  const userData =
    decryptedBytes && JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  const [Videos, setVideos] = useState([]);
  const [index, setIndex] = useState(0);
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [checkOwn, setCheckOwn] = useState("loading");
  const [courseDetails, setCourseDetails] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const getUnitData = () => {
    setLoading(true);
    const data_send = {
      student_id: userData?.student_id,
      token_value: userData?.token_value,
      course_id: params.get("course_id"),
    };
    axios
      .post(
        base_url + "/user/courses/select_course_lesson.php",
        JSON.stringify(data_send)
      )
      .then(async (res) => {
        if (res.data.status == "success") {
          const videosData = res.data.message.map((unit) => ({
            id: unit.unit_id,
            title: unit.unit_name,
            videos: unit.videos.map((video) => ({
              id: video.video_id,
              name: video.video_title,
              link_video: video.youtube_id,
              loom_url: video?.loom_url,
            })),
          }));
          console.log(videosData);
          setCheckOwn(res?.data?.message[0]?.videos[0]?.own);
          setVideos(videosData);
          let allcourses = [...res.data.message];
          let pushedData = [];
          for (let i = 0; i < allcourses.length; i++) {
            let obj = {
              ...allcourses[i],
              show: false,
            };
            pushedData.push(obj);
          }
          setCourseDetails(pushedData);
        } else if (res.data.status === "out") {
          localStorage.clear();
          
          window.location.reload();
        } else {
          dispatch(showToogleTooltib());
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUnitData();
  }, []);
  const [selectedVideo, setSelectedVideo] = useState({
    objId: 1,
    videoId: 1,
  });

  useEffect(() => {
    if (Videos?.length) {
      console.log(Videos[0]?.videos[0]);
      setSelectedVideo({ ...selectedVideo, videoId: Videos[0]?.videos[0]?.id });
    }
  }, [Videos]);
  const [videoObj, setVideoObj] = useState({});

  const [showTable, setShowTable] = useState(true);
  useEffect(() => {
    if (Videos && Videos?.length) {
      //   const filteredObject = Videos.filter(
      //     (videoObj) => videoObj.id === selectedVideo.objId
      //   );
      //   console.log("filteredObject", filteredObject);
      //   let filteredVideo = filteredObject.map((videos) =>
      //     videos.videos.filter((video) => video.id === selectedVideo.videoId)
      //   );
      //   setVideoObj(filteredVideo.flat()[0]);
      //   setListOfVideos(filteredObject[0]?.videos?.length);
    }
  }, [Videos, selectedVideo]);

  useEffect(() => {
    setVideoObj(() => Videos[tab]?.videos[index]);
  }, [index, Videos]);

  function handleSelectVideo(objId, videoId, title, LessonName) {
    setSelectedVideo({ objId, videoId });
  }

  function handleNextVideo() {
    if (index < Videos[tab]?.videos?.length - 1) {
      setIndex((prevState) => {
        return ++prevState;
      });
    } else {
      if (tab < Videos?.length - 1) {
        setTab((prevState) => {
          return ++prevState;
        });
        setIndex(0);
      }
    }
  }

  function handlePrevVideo() {
    if (index >= 1) {
      setIndex((prevState) => {
        return --prevState;
      });
    } else {
      if (tab >= 1) {
        setTab((prevState) => {
          return --prevState;
        });
      }
    }
  }

  return (
    <>
      {" "}
      {checkOwn == "loading" ? (
        <ContentLoader
          viewBox="0 0 980 420"
          speed={1}
          // backgroundColor={'green'}
        >
          {/* Card shapes */}
          <rect x="10" y="10" rx="20" ry="20" width="960" height="600" />
          {/* <rect x="350" y="10" rx="20" ry="20" width="260" height="300" /> */}
          {/* <rect x="690" y="10" rx="20" ry="20" width="260" height="300" /> */}
          {/* <rect x="690" y="10" rx="20" ry="20" width="260" height="300" /> */}
        </ContentLoader>
      ) : Videos && Videos?.length ? (
        <div>
          {checkOwn == "loading" ? (
            <ContentLoader
              viewBox="0 0 980 420"
              speed={1}
              // backgroundColor={'green'}
            >
              {/* Card shapes */}
              <rect x="10" y="10" rx="20" ry="20" width="960" height="600" />
              {/* <rect x="350" y="10" rx="20" ry="20" width="260" height="300" /> */}
              {/* <rect x="690" y="10" rx="20" ry="20" width="260" height="300" /> */}
              {/* <rect x="690" y="10" rx="20" ry="20" width="260" height="300" /> */}
            </ContentLoader>
          ) : checkOwn ? (
            <>
              {" "}
              <div className="courseContent">
                {loading ? (
                  <ContentLoader
                    viewBox="0 0 980 320"
                    speed={1}
                    // backgroundColor={'green'}
                  >
                    {/* Card shapes */}
                    <rect
                      x="10"
                      y="10"
                      rx="20"
                      ry="20"
                      width="260"
                      height="300"
                    />
                    <rect
                      x="350"
                      y="10"
                      rx="20"
                      ry="20"
                      width="260"
                      height="300"
                    />
                    <rect
                      x="690"
                      y="10"
                      rx="20"
                      ry="20"
                      width="260"
                      height="300"
                    />
                    <rect
                      x="690"
                      y="10"
                      rx="20"
                      ry="20"
                      width="260"
                      height="300"
                    />
                  </ContentLoader>
                ) : (
                  <main>
                    <div className="flexRow">
                      <button
                        className="btn btn-success"
                        onClick={() => window.open(params?.get("p"), "_blanck")}
                      >Books</button>
                      <div className="home-text-box">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowTable(!showTable)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                            />
                          </svg>
                        </div>
                        {/* <p>Lesson {videoObj?.id}:</p> */}
                        <p>{videoObj?.name}</p>
                      </div>
                    </div>

                    <div className="videos">
                      <TableofContent
                        handlePrevVideo={handlePrevVideo}
                        handleNextVideo={handleNextVideo}
                        setTab={setTab}
                        tab={tab}
                        showContent={showTable}
                        setShowTable={setShowTable}
                        videoObj={videoObj}
                        Videos={Videos}
                        index={index}
                        setIndex={setIndex}
                        selectedVideo={selectedVideo}
                        handleSelectVideo={handleSelectVideo}
                      />
                    </div>
                  </main>
                )}
              </div>
            </>
          ) : (
            <h2 style={{ padding: "20px", fontWeight: "900" }}>
              You Are Not Register This Course
            </h2>
          )}
        </div>
      ) : (
        <h2 style={{ padding: "20px", fontWeight: "900" }}>
          No Lessons In This Course
        </h2>
      )}
    </>
  );
};

export default CourseContent;
