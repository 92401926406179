import React, { useEffect, useState } from "react";
import "./allcourses.css";
import AllCoursesBanner from "./AllCoursesBanner/AllCoursesBanner";
import Footer from "../../components/Footer/Footer";
import CryptoJS from "crypto-js";
import Skeleton from "react-loading-skeleton";
import { getCourses } from "./functions/getAll";
import { MdPlayLesson } from "react-icons/md";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

const CoursesquestionsLanding = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [originalCourses, setOriginalCourses] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const localData = localStorage.getItem("elmataryapp");
  const decryptedBytes = localData && CryptoJS.AES.decrypt(localData, "111");
  const userData =
    decryptedBytes && JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  const { id } = useParams();

  useEffect(() => {
    getCourses(userData, setPageLoading, setCourses, setOriginalCourses, id);
  }, []);

  console.log(courses);

  return (
    <>
      <div className="allcourses">
        {/* <AllCoursesBanner /> */}
        
        <div className="question-bank-info">
          <h3
            style={{
              marginTop: "50px",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              fontSize: "28px",
              fontWeight: "bold",
            }}
          >
            Question Bank Overview
          </h3>
          <p
            style={{
              textAlign: "center",
              maxWidth: "800px",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "30px",
              fontSize: "15px",
              color: "grey",
            }}
          >
            Our question bank provides a comprehensive collection of questions across various medical disciplines. It's an excellent resource for students to practice and test their knowledge.
          </p>
          <h4
            style={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            How to Use the Question Bank
          </h4>
          <ol
            style={{
              textAlign: "left",
              maxWidth: "800px",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "30px",
              fontSize: "15px",
              color: "grey",
            }}
          >
            <li>Access the question bank by logging into your account and navigating to the 'Question Bank' section.</li>
            <li>Select the medical discipline or specific topic you want to practice.</li>
            <li>Browse through the collection of questions and choose ones to practice.</li>
            <li>Attempt the questions in a timed or untimed setting based on your preference.</li>
            <li>Review your answers and explanations for each question to understand your mistakes.</li>
            <li>Track your progress and performance over time to identify areas that need improvement.</li>
            <li>Utilize the question bank regularly as part of your study routine to reinforce your learning.</li>
            <Link
            to="/signup"
            className="btn btn-secondary"
            style={{ display: "block", textAlign: "center", margin: "20px auto" }}
          >
            Access Question Bank Now
          </Link>
          </ol>
         
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CoursesquestionsLanding;
