import axios from "axios";
import Quiz from "../../components/Quiz/Quiz";
import { questions } from "../../utils/data";
import { base_url } from "../../constants";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import CryptoJS from "crypto-js";
import { useSearchParams } from "react-router-dom";
export default function QuestionBank() {
  const { id } = useParams();
  const localData = localStorage.getItem("elmataryapp");
  const decryptedBytes = localData && CryptoJS.AES.decrypt(localData, "111");
  const [pageLoading, setPageLoading] = useState(false);
  const userData =
    decryptedBytes && JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  const [exams, setExams] = useState([]);
  const [course_id] = useSearchParams();
  useEffect(() => {
    const getCourses = () => {
      const data_send = {
        student_id: userData?.student_id,
        token_value: userData?.token_value,
        unit_id: id,
        course_id: course_id.get("course_id"),
      };
      axios
        .post(
          base_url + "/user/courses/select_questions.php",
          JSON.stringify(data_send)
        )
        .then((res) => {
          if (res.data.status == "success") {
            setExams(res.data.message);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setPageLoading(false);
        });
    };
    getCourses();
  }, []);
  return (
    <div style={{ padding: "40px" }}>
      <h1>Questions Bank</h1>
      <Quiz data={exams} />
    </div>
  );
}
