import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import './profile.css';
import Modal from '../../components/modal/index';
import ProfileCourses from './ProfileCourses/ProfileCourses';
import { coursesData } from './ProfileCourses/data';
import { useNavigate } from 'react-router';
import Footer from '../../components/Footer/Footer';
import { Axios } from '../../components/axios';
import { toast } from 'react-toastify';
import { getStdCourses } from './functions/getStudentCourses';
import axios from 'axios';
import { base_url } from '../../constants';
import { Spinner } from 'react-bootstrap';
import { MdPlayLesson } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ProfileInfo from './ProfileInfo/ProfileInfo';
import { getCourses } from '../AllCourses/functions/getAll';
import ProfileSidebar from './ProfileSidebar/ProfileSidebar';
const Profile = () => {
  const navigate = useNavigate();
  const [originalCourses, setOriginalCourses] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);

  const [courses, setCourses] = useState([]);
  const [logoutLoading, setLogOutLoading] = useState(false);
  const [compCourses, setCmpCourses] = useState([]);
  const localData = localStorage.getItem('elmataryapp');
  const decryptedBytes = localData && CryptoJS.AES.decrypt(localData, '111');
  const userData =
    decryptedBytes && JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  function getCourses() {
    navigate('/studentCourses', {
      state: {
        courses
      }
    })
  }

  const handleLogOut = () => {
    setLogOutLoading(true);
    const data_send = {
      student_id: userData?.student_id,
      token_value: userData?.token_value,
    };
    axios
      .post(
        base_url + '/user/auth/student_logout.php',
        JSON.stringify(data_send)
      )
      .then(async (res) => {
        if (res.data.status == 'success') {
          toast.success(res.data.message);
          localStorage.removeItem('elmataryapp');
          navigate('/signup', { replace: true });
        } else if (res.data.status == 'error') {
          toast.error(res.data.message);
        } else if (res.data.status == 'out') {
          localStorage.clear();
          

          window.location.reload();
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLogOutLoading(false);
      });
    // window.location.reload()
  };

  useEffect(() => {
    getStdCourses(
      userData,
      setPageLoading,
      setCourses,
      setOriginalCourses,
      setCmpCourses
    );
  }, []);
  console.log(courses, originalCourses, compCourses);


  return (
    <div className="profile_page">

      <div className='profile-welcome'>
        <h3>Welcome Back <span>{userData?.student_name}</span></h3>
        
      </div>

      <div className='profile-content'>
        <ProfileSidebar handleLogOut={handleLogOut} />
        <ProfileInfo userData={userData} />
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
