import React, { useEffect, useState } from 'react';
import './registration.css';
import { universitiesData } from './data';
import 'react-phone-input-2/lib/style.css';
import './registration.css';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router';
import axios, { Axios } from 'axios';
import {
  API_ROUTES,
  BASES_ROUTES,
  BASE_URL,
} from '../../components/axios/BASE_URL';
import { toast } from 'react-toastify';
import { base_url } from '../../constants';
import { Sign1, checkCode } from './functions/Sign1';
import { Spinner } from 'react-bootstrap';
import { PiHandWavingBold } from "react-icons/pi";


const Registration = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState('');
  const [userCode, setUserCode] = useState('');
  const [selectedUnis, setSelectedUnis] = useState('');
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState('');
  const [universities, setUniversities] = useState([]);
  const [registData, setRegistData] = useState({
    email: '',
  });
  const [regData2, setRegData2] = useState({
    pass: '',
    student_name: '',
    university_id: '',
    grade_id: '',
    phone: '',
  });
  const [changeShow, setChangeShow] = useState('email');
  const [confPass, setConfPass] = useState('');
  const [signLoading, setSignLoading] = useState(false);
  const handleSign2 = () => {
    if (userCode == '') {
      toast.warn('Enter Code');
      return;
    }
    if (code) {
      checkCode(registData?.email, userCode, navigate, registData);
    } else {
      toast.warn('Check The Code');
      return;
    }
  };
  return (
    <div className="registration">
      {changeShow == 'email' ? (

        <form
          onSubmit={(e) => {
            e.preventDefault();
            Sign1(registData, setSignLoading, setChangeShow, setCode);
          }}
        >
          <div className='formTitle'>Sign Up</div>
          <div className='welcome my-4'>
            <div>Welcome ! <i class="fa-solid fs-5 fa-hands-clapping"></i></div>
            <span>Please Create an Account below</span>
          </div>
          <div>
            <label htmlFor="email">Enter Your Email</label>
            <input
              onChange={(e) => {
                setRegistData({ ...registData, email: e.target.value });
              }}
              id="email"
              placeholder="Email"
              type="text"
              className="form-control"
            />
          </div>
          <div className="actions">
            <button className='btn btn-success w-100 my-3'>{signLoading ? <Spinner /> : 'Sign Up'}</button>
          </div>
          <hr />
          <h5>
            <span>You Already Have an Account?</span>
            <span onClick={() => navigate("/login")}>Login</span>
          </h5>
        </form>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSign2();
          }}
          action=""
        >
           <h4 className="my-10">OTP Verification </h4>
          <span style={{ fontSize: "13px" }}>
            A 6-digit code has been sent to your register email{" "}
            <span style={{ color: "red" }}>"{registData?.email}"</span>
            <p>
              <i style={{ fontSize: "12px", color: "darkgray" }}>
                If You Don't Find The Code In Your Email Inbox - Just Check{" "}
                <span style={{ color: "red" }}> " Spam " </span>
                Inbox
              </i>
            </p>
          </span>
          <input
            className="form-control mb-2 my-10"
            type="text"
            maxLength={6}
            onChange={(e) => {
              setUserCode(e.target.value);
            }}
            placeholder="Enter Code That Delivared To Your Email"
          />
          <button className='btn btn-success w-100 my-3'>Confirm</button>
        </form>
      )}
    </div>
  );
};

export default Registration;
