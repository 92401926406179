import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import logo from "../../assets/logo/medLearningHub.png";

const Footer = () => {
  return (
    <footer className="footer-section text-dark">
      <div className="container">
        <div className="footer-content py-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="footer-widget">
                <div className="footer-text">
                  <img
                    className="footerImage"
                    width={130}
                    height={130}
                    src={logo}
                    alt="logo"
                  />
                  <p>
                    Stay connected with us and enjoy ongoing exclusive offers
                    designed just for our followers.
                  </p>
                  <span className="fw-semibold mt-2">
                    Follow us on social media:
                  </span>
                  <div className="mb-3 mt-2">
                    <span
                      onClick={() =>
                        window.open(
                          "https://www.facebook.com/profile.php?id=61562063557757&mibextid=LQQJ4d",
                          "_blanck"
                        )
                      }
                    >
                      {" "}
                      <i class="fa-brands text-secondary bg-body-tertiary mx-2 rounded-5 fa-facebook-f"></i>
                    </span>{" "}
                    <span
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/medicotoon?igsh=dnR3OGgwemFhZHp4",
                          "_blanck"
                        )
                      }
                    >
                      {" "}
                      <i class="fa-brands text-secondary bg-body-tertiary mx-2 rounded-5 fa-instagram"></i>
                    </span>{" "}
                    {/* <i class="fa-brands text-secondary bg-body-tertiary mx-2 rounded-5 fa-linkedin-in"></i> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-widget mt-4 col-xl-2 col-lg-2 col-3">
              <div className="footer-widget-heading">
                <h3>Explore</h3>
              </div>
              <ul>
                <li>
                  <Link to={"/"}>Home</Link>
                </li>
                <li>
                  <Link to="/allcourses">Courses</Link>
                </li>
              </ul>
            </div>
            <div className="footer-widget mt-4 col-xl-2 col-lg-2 col-3">
              <div className="footer-widget-heading">
                <h3>Links</h3>
              </div>
              <ul>
                <li>
                  <Link to={"/exams"}>Exams</Link>
                </li>
                <li>
                  <Link to="/questions">Questions Bank</Link>
                </li>
              </ul>
            </div>
            <div className="col-xl-4 mt-4 col-lg-4 col-md-6 mb-50">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Sign up</h3>
                </div>
                <div className="footer-text">
                  <p className="mb-4">
                    Don’t miss to subscribe to our new feeds, kindly fill the
                    form below.
                  </p>
                </div>
                <div className="subscribe-form">
                  <form action="#">
                    <input className="form-control" placeholder="Email" />
                    <button className="btn btn-success rounded-0">
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area text-white">
        <div className="container">
          <center className="text-center text-lg-left">
            <div className="copyright-text">
              <p>
                Copyright &copy; 2024, All Right Reserved{" "}
                <a href="https://elmataryweb.com">Medicotoon</a>
              </p>
            </div>
          </center>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
