export const booksData=[
  {
    book_id:1,
    book_name:'must 502',
  },
  {
    book_id:2,
    book_name:'Mrcs',
  },
  {
    book_id:3,
    book_name:'Curruclim Ain Shams',
  },
  {
    book_id:4,
    book_name:'ASU6_2024 ',
  },
  {
    book_id:5,
    book_name:'must 601',
  },
  {
    book_id:1,
    book_name:'must 502',
  },
  {
    book_id:2,
    book_name:'Mrcs',
  },
  {
    book_id:3,
    book_name:'Curruclim Ain Shams',
  },
  {
    book_id:4,
    book_name:'ASU6_2024 ',
  },
  {
    book_id:5,
    book_name:'must 601',
  },
]
