import { useNavigate } from "react-router";
import "./Exams.css";
import { useEffect, useState } from "react";
import { base_url } from "../../constants";
import CryptoJS from "crypto-js";
import axios from "axios";
export default function Exams() {
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const localData = localStorage.getItem("elmataryapp");
  const decryptedBytes = localData && CryptoJS.AES.decrypt(localData, "111");
  const userData =
    decryptedBytes && JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  const [exams, setExams] = useState([]);
  useEffect(() => {
    const getCourses = () => {
      const data_send = {
        student_id: userData?.student_id,
        token_value: userData?.token_value,
      };
      axios
        .post(
          base_url + "/user/courses/select_exam.php",
          JSON.stringify(data_send)
        )
        .then((res) => {
          if (res.data.status == "success") {
            setExams(res.data.message);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setPageLoading(false);
        });
    };
    getCourses();
  }, []);
  return (
    <div style={{ padding: "40px" }}>
      <h1>Your Exams</h1>

      <div className="exams_card_container" style={{ gap: "10px", justifyContent:"flex-start" }}>
        {exams && exams?.length
          ? exams?.map((item) => {
              return (
                <div
                  className="exam_card"
                  onClick={() => {
                    navigate(`/examQuestion/${item?.exam_id}`, {
                      state:{
                        timer: item?.exam_time
                      }
                    });
                  }}
                >
                  <h2>{item?.exam_name}</h2>
                  <div>
                    <p>Description: {item?.exam_description}</p>
                    <p>Time Allowed: {item?.exam_time} Min.</p>
                    <p>End Date: {item?.end_date}</p>
                  </div>
                </div>
              );
            })
          : null}

      
      </div>
    </div>
  );
}
