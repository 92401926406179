export const ReviewsData=[
  {
    review_id:1,
    user_data:{
      user_id:1,
      user_name:'AbdelRahman Ali',
      image:require("../../../assets/stu.png"),
    },
    review:'ساعدنى الكورس ده علشان افهم جزئيه مش فاهمها شكرا للمحاضر وشكرا ايضا للمبرمج الجامد إلى عمل الموقع بالسهوله دى عن الموقع الى قبل ده',
  },
  {
    review_id:2,
    user_data:{
      user_id:1,
      user_name:'ali malol',
      image:require("../../../assets/stu.png"),
    },
    review:'ساعدنى الكورس ده علشان افهم جزئيه مش فاهمها شكرا للمحاضر وشكرا ايضا للمبرمج الجامد إلى عمل الموقع بالسهوله دى عن الموقع الى قبل ده',
  },
]
