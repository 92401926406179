import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./header.css";
import CryptoJS from "crypto-js";
import axios from "axios";
import { base_url } from "../../constants";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import PopUp from "../popup";
import CourseIntroVideo from "../../pages/CourseDetails/CourseInfo/CourseIntroVideo/CourseIntroVideo";
import { Plus } from "./icons";
import logo from "../../assets/logo/medLearningHub.png";

const Header = () => {
  const [showPerLinks, setShowPerLinks] = useState(false);
  const navigate = useNavigate();
  const localData = localStorage.getItem("elmataryapp");
  const decryptedBytes = localData && CryptoJS.AES.decrypt(localData, "111");
  const userData =
    decryptedBytes && JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [logoutLoading, setLogOutLoading] = useState(false);
  useEffect(() => {
    axios
      .post(
        base_url + "admin/subscription/new_create_cources_cards.php",
        JSON.stringify({
          copoun_name: "hh2",
          copoun_quantity: "1",
          subjects: "1",
          phone_number: "232",
          end_date: "2024-06-21",
          admin_id: "1",
          access_token: "570QGK5gmHVzk8dumpud",
        })
      )
      .then((data) => console.log(data));
  }, []);
  const handleLogOut = () => {
    setLogOutLoading(true);
    const data_send = {
      student_id: userData?.student_id,
      token_value: userData?.token_value,
    };
    axios
      .post(
        base_url + "/user/auth/student_logout.php",
        JSON.stringify(data_send)
      )
      .then(async (res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          localStorage.removeItem("elmataryapp");
          window.location.reload();
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else if (res.data.status == "out") {
          localStorage.clear();

          // window.location.reload();
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLogOutLoading(false);
      });
    // window.location.reload()
  };

  return (
    <div className="header">
      <div className="container2">
        <div className="right">
          <div onClick={() => navigate("/")} className="logo">
            <img src={logo} alt="" />
          </div>
          <div className={show ? "links open" : "links"}>
          <span className="cart_logo" style={{cursor:"pointer", color:"white", fontSize:"22px",}} onClick={()=>setShow(false)}>&times;</span>
          <NavLink to={"/"}>Home</NavLink>
            {/* <NavLink to={"/book"}>
            books
          </NavLink> */}
            {userData && Object.keys(userData).length > 0 ? (
              <>
                {" "}
                <NavLink to={"/allcourses"}>Courses</NavLink>
                <NavLink to={"/studentCourses"}>My Courses</NavLink>
                <NavLink to="/exams">Exams</NavLink>
                <NavLink to="/questions">Questions Bank</NavLink>
              </>
            ) : (
              <>
                <NavLink to={"/allcourses"}>Courses</NavLink>
                {/* <NavLink to={"/studentCourses"}>My Courses</NavLink> */}
                <NavLink to="/exams">Exams</NavLink>
                <NavLink to="/questions">Questions Bank</NavLink>
                {/* <NavLink to={"/signup"}>Register</NavLink> */}
                <NavLink to={"/login"}>Log In</NavLink>
              </>
            )}
            {/* {userData && Object.keys(userData).length > 0 ? null : (
              <NavLink to={"/signup"}>Register</NavLink>
            )} */}
          </div>
        </div>
        <PopUp open={open} setOpen={setOpen} title={"Subscripe Now"}>
          <CourseIntroVideo />
        </PopUp>
        <div className="left">
          <div className="person_logo">
            {userData && Object.keys(userData).length > 0 ? (
              <span
                className="mx-4 plusIco"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/supscripe`)}
              >
                <i class="fa-solid fa-plus"></i>Add More Courses
              </span>
            ) : null}
            <div className="cart_logo">
              <img
                onClick={() => {
                  setShow(!show);
                }}
                src={
                  "https://cdn.icon-icons.com/icons2/916/PNG/512/Menu_icon_icon-icons.com_71858.png"
                }
                alt=""
              />
            </div>
            <i
              class="fa-solid fs-2 m-3 userToggle fa-user"
              onClick={() => {
                setShowPerLinks(!showPerLinks);
              }}
            ></i>
            {/* <img
              src={require("../../assets/stu.png")}
              alt=""
              onClick={() => {
                setShowPerLinks(!showPerLinks);
              }}
            /> */}
            {showPerLinks ? (
              <div className="logo_links">
                {userData && (
                  <div>
                    <i class="fa-solid fs-4 m-2 fa-user"></i>
                    {/* <img src={require("../../assets/stu.png")} alt="" /> */}
                    <div className="details">
                      <h5>{userData.student_name}</h5>
                      <p>{userData.student_email}</p>
                    </div>
                  </div>
                )}
                <div className="links" style={{ flexDirection: "column" }}>
                  {userData && Object.keys(userData).length > 0 ? (
                    <div
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      <img src={require("../../assets/user.png")} alt="" />
                      <span>Account</span>
                    </div>
                  ) : null}
                  {/* <div
                  onClick={()=>{
                    navigate("/contact")
                  }}
                >
                  <img src={require("../../assets/messages.png")} alt="" />
                  <span>Contact Us</span>
                </div> */}
                  <div
                    onClick={() => {
                      navigate("/techsup");
                    }}
                  >
                    <img src={require("../../assets/help.png")} alt="" />
                    <span>Technical Support</span>
                  </div>
                  {userData && Object.keys(userData).length > 0 ? (
                    <div
                      onClick={() => {
                        return logoutLoading ? null : handleLogOut();
                      }}
                    >
                      {logoutLoading ? (
                        <Spinner />
                      ) : (
                        <>
                          <img
                            src={require("../../assets/signout.png")}
                            alt=""
                          />
                          <span>Sign Out</span>
                        </>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
