import React from 'react'
import './about.css'
import aboutImg from '../../assets/home-slider-2.jpg';

export default function About() {
    return <>
        <div className='container my-5 about_container'>
            <div className="row gap-4">
                <div className="col-md-6 d-flex  justify-content-start">
                    <img style={{ width: "600px" }} src={"https://res.cloudinary.com/duovxefh6/image/upload/v1720684202/IMAGE_1_page-0001_crx5qp.jpg"} />
                    {/* <div className='col-md-6  d-flex gap-3 justify-content-center align-items-start'>
                        <div>
                            <img src="https://wphix.com/html/eduker-html/eduker/assets/img/about/about-1.jpg" className='w-75 my-2' alt="img" />
                            <img src="https://wphix.com/html/eduker-html/eduker/assets/img/about/about-3.jpg" className='w-50' alt="img" />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <img src="https://wphix.com/html/eduker-html/eduker/assets/img/about/about-2.jpg" className='w-100' alt="img" />
                    </div> */}
                </div>
                <div className="col-md-5 textContain">
                    <span className='main-text'>About Medicotoon</span>
                    <h1>The way to success start here</h1>
                    <p className='my-4'>Welcome to Medicotoon Academy, where excellence in Medical education is our hallmark. Our comprehensive medical courses, taught by experienced professionals, ensures you receive top-tier knowledge and practical skills</p>
                    {/* <div className='d-flex flex-wrap gap-2'>
                        <h6 className='w-100'><i class="fa-solid bg-secondary-subtle main-text  p-1 rounded-5 fa-check"></i> Access to all our courses</h6>
                        <h6 className='w-100'><i class="fa-solid bg-secondary-subtle main-text  p-1 rounded-5 fa-check"></i> Learn the latest skills</h6>
                        <h6 className='w-100'><i class="fa-solid bg-secondary-subtle main-text  p-1 rounded-5 fa-check"></i> Upskill your organization</h6>
                    </div> */}
                </div>
            </div>
        </div>
    </>
}
